import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { TabView, TabPanel } from 'primereact/tabview';
import ConcursoResp from "./ConcursoRespo";
import UserDocumentsGrid from "./concursovideosusuarios";
import TodosGrid from "./concursovideos/todos"
import Loading from "../loading/Loading";

const ConcursoResppanel = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(
                    `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
                );
                setUserRole(response.data.role);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener el rol del usuario:", error);
                setLoading(false);
            }
        };

        fetchUserRole();
    }, [auth.id]);

    if (loading) {
        return <div> <Loading /></div>; // Mostrar un mensaje de carga mientras se obtiene el rol del usuario
    }

    return (
        <div id="panelAuto" style={{ border: '1px solid #000', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '5px', width: '100%', maxWidth: '1000px', margin: 'auto' }}>
            <center>
                <h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">JUGA X JUGAR, EL SPOT</h1>
            </center>
            <p />
            <TabView style={{ width: '100%' }}>
                <TabPanel header="Cargar Videos" disabled={!userRole || (userRole === "user" || userRole === "jurado")}>
                    {!(!userRole || (userRole === "user" || userRole === "jurado")) && (  // Verifica si no está deshabilitado
                        <div style={{ padding: '10px' }}>
                            <ConcursoResp />
                        </div>
                    )}
                </TabPanel>

                <TabPanel header="Mis Videos" disabled={!userRole || !(userRole === "admin" || userRole === "adminauto" || userRole === "user")}>
                    <div style={{ padding: '10px' }}>
                        <UserDocumentsGrid />
                    </div>
                </TabPanel>
                {userRole === "admin" || userRole === "adminauto" || userRole === "jurado" ? (
                    <TabPanel header="Todos">
                        <div style={{ padding: '10px' }}>
                            {/* Contenido para el tab "Todos" */}
                            <TodosGrid></TodosGrid>
                            {/* Aquí puedes añadir el contenido que desees mostrar */}
                        </div>
                    </TabPanel>
                ) : null}
            </TabView>
        </div>
    );
}

export default ConcursoResppanel;
